<template>
	<div>
		<div class="pd30">
			<a-alert message="需要开启微信支付企业付款到零钱接口" banner />
			
			
			<div class="mt20">
				<div class="wxb-table-gray">
					<a-table rowKey="withdraw_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
					 :loading="loading">
						<div class="flex alcenter center" slot="money" slot-scope="money,record">
							{{money}}元
						</div>
						
						
						<div class="flex alcenter center" slot="action" slot-scope="record">
							<a-button type="primary" ghost v-if="record.status==0" @click="agreeAct(record)">同意</a-button>
							<!-- <a-button type="danger" ghost v-if="record.status==0" class="ml10">拒绝</a-button> -->
							<div v-if="record.status != 0">-</div>
						</div>
					</a-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../../../../common/mixin/list.js';
	export default{
		props:{
			partner_id:{
				type:Number,
				default:0
			},
		},
		mixins:[listMixin],
		data(){
			return{
				loading:false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{title: '时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '提现金额',dataIndex: 'money',align: 'center',scopedSlots: {customRender: 'money'}},
					{title: '状态',dataIndex: 'status_means',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
				],
				datas: [],
			}
		},
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getPartnerDetailWithdrawList',{
					partner_id:this.partner_id,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					role_id:3,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			agreeAct(record){
				this.$confirm({
					title:'确定同意这笔提现吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/agreePartnerWithdraw',{
								withdraw_id:record.withdraw_id,
							}).then(res=>{
								this.$message.success('提现成功',1,()=>{
									this.getLists();
								});
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
</style>
